import LinkBase, { LinkProps } from './LinkBase';

type IconLinkProps = LinkProps & {

}

export default function IconLink (props: IconLinkProps) {
    return (
        <LinkBase {...props} variant='button'>
            {props.children}
        </LinkBase>
    )
}
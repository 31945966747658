import Divider from '@mui/material/Divider';

type VerticalDividerProps = {
    color?: string;
    flexItem?: boolean;
}

export default function VerticalDivider (props: VerticalDividerProps) {
    const { flexItem = false, color } = props;

    return (
        <Divider sx={{ borderColor: color }} orientation='vertical' flexItem={flexItem} light/>
    )
}
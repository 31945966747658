type ShopifyBuyButtonProps = {
    componentId: string;
}

export default function ShopifyBuyButton (props: ShopifyBuyButtonProps) {

    const { componentId } = props;

    return (
        <div id={`product-component-${componentId}`}></div>
    )
}
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as InstagramSvg } from './svgs/instagram.svg';

type InstagramIconProps = {
    color: 'primary' | 'secondary';
    size: 'small' | 'medium';
}

export default function InstagramIcon (props: InstagramIconProps) {
    const { color, size } = props;

    return (
        <SvgIcon component={InstagramSvg} color={color} fontSize={size} inheritViewBox />
    )
}
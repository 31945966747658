import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

type ImageProps = {
    styles?: SxProps;
    src: string;
}

export default function Image (props: ImageProps) {
    const { styles, src } = props;

    return (
        <Box component='img' sx={styles} src={src} />
    )
}
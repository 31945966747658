import LinkBase, { LinkProps } from './LinkBase';

type TextLinkProps = LinkProps & {
}

export default function TextLink (props: TextLinkProps) {
    return (
        <LinkBase 
            {...props}
            variant='h4'>
            {props.children}
        </LinkBase>
    )
}
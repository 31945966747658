import { SxProps } from '@mui/material';
import MuiLink from '@mui/material/Link';

export type LinkProps = {
    underline?: boolean;
    underlineColor?: string;
    styles?: SxProps;
    target?: string;
    color?: string;
    hoverColor?: string;
    capitalize?: boolean;
    children: JSX.Element | string;
    href: string;
}

type LinkBaseProps = LinkProps & {
    variant: 'body1' | 'h4' | 'button'
}

export default function LinkBase (props: LinkBaseProps) {
    const { 
        styles, 
        hoverColor,
        target = '_blank',
        color = 'primary',
        variant, 
        capitalize = false,
        underline = false, 
        href 
    } = props;

    return (
        <MuiLink
            sx={{
                '&:hover': {
                    color: hoverColor,
                    cursor: 'pointer'
                },
                textDecorationColor: 'inherit',
                fontWeight: 700,
                letterSpacing: '0.1em',
                textTransform: capitalize ? 'uppercase' : 'none',
                ...styles
            }}
            href={href}
            target={target}
            color={color}
            variant={variant}
            underline={underline ? 'always' : 'none'}>
            {props.children}
        </MuiLink>
    )
}
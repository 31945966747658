import { JSX } from 'react';
import MuiGrid from '@mui/material/Grid';
import { SxProps } from '@mui/material';

type AlignmentOpts = 'normal' | 'space-between' | 'space-around' | 'flex-start' | 'flex-end' | 'center';

type GridProps = {
    children: JSX.Element[] | JSX.Element;
    styles?: SxProps;
    container?: boolean;
    item?: boolean;
    vertical?: boolean;
    columns?: number;
    justifyContent?: AlignmentOpts;
    alignItems?: AlignmentOpts;
    flex?: number;
}

export default function Grid (props: GridProps) {
    const {
        container = true,
        item = false, 
        vertical = false,
        justifyContent = 'normal',
        alignItems = 'normal',
        columns,
        flex = '',
        styles
    } = props;
    const direction = vertical === true ? 'column' : 'row';

    return (
        <MuiGrid 
            container={container}
            item={item} 
            direction={direction} 
            justifyContent={justifyContent} 
            columns={columns}
            alignItems={alignItems} 
            flex={flex}
            sx={styles}>
            {props.children}
        </MuiGrid>
    )
}
import { SxProps } from '@mui/material';
import MuiTypography from '@mui/material/Typography';

declare module '@mui/material/styles' {
    interface TypographyVariants {
      span: React.CSSProperties;
    }
  
    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
      span?: React.CSSProperties;
    }
  }
  
  // Update the Typography's variant prop options
  declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
      span: true;
    }
  }

type TextVariants = 'span' | 'body1' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type TextProps = {
    children: string | JSX.Element | (string|JSX.Element)[] | JSX.Element[];
    color?: string;
    styles?: SxProps;
    textAlign?: 'initial' | 'inherit' | 'center' | 'left' | 'right';
    textDecoration?: 'none' | 'underline';
    textDecorationColor?: string;
    maxWidth?: string;
    lineHeight?: string;
    letterSpacing?: string;
    fontWeight?: number;
    underline?: boolean;
    fontFamily?: string;
}

type TextBaseProps = TextProps & {
    variant: TextVariants;
}

export default function TextBase (props: TextBaseProps) {
    const { 
        styles, 
        color, 
        variant,
        textAlign = 'initial', 
        maxWidth,
        textDecoration,
        textDecorationColor,
        fontWeight, 
        letterSpacing, 
        lineHeight,
        fontFamily
    } = props;

    return (
        <MuiTypography 
            variant={variant}
            color={color} 
            sx={{
                textAlign: textAlign,
                lineHeight: lineHeight,
                maxWidth: maxWidth,
                letterSpacing: letterSpacing,
                fontWeight: fontWeight,
                textDecoration: textDecoration,
                textDecorationColor: textDecorationColor,
                fontFamily: fontFamily,
                ...styles,
            }} >
            {props.children}
        </MuiTypography>
    )
}
import Box from '../../components/containers/Box';
import Stack from '../../components/containers/Stack';
import Logo from './Logo';
import Paragraph from '../../components/text/Paragraph';
import InstagramIcon from '../../components/icons/InstagramIcon';
import FacebookIcon from '../../components/icons/FacebookIcon';
import YoutubeIcon from '../../components/icons/YoutubeIcon';
import IconLink from '../../components/links/IconLink';
import IconButton from '../../components/buttons/IconButton';
import Divider from '../../components/dividers/VerticalDivider';
import theme from '../../theme/theme';
import JoinTheHerd from '../jointheherd/JoinTheHerd';
import { useScreenSize } from '../../theme/breakpoints';

export default function Footer () {
    const isSmallScreen = useScreenSize('sm');

    return (
        <Box styles={{ backgroundColor: 'primary.main', padding: theme.spacing(isSmallScreen ? 5 : 6, 0) }}>
            <JoinTheHerd />

            <Stack 
                direction='column'
                justifyContent='space-between' 
                alignItems='center'
                styles={{ width: '100%', height: '100%' }}>
                <IconLink href='/' target='_self'>
                    <Logo />
                </IconLink>

                <Box>
                    <Stack direction='row' spacing={2}>
                        <IconLink href='https://www.instagram.com/goathousegym/'>
                            <IconButton>
                                <InstagramIcon color='secondary' size='small' />
                            </IconButton>
                        </IconLink>

                        <IconLink href='https://www.youtube.com/channel/UCfl3OrHQaPCUoIGqiGbO93w'>
                            <IconButton>
                                <YoutubeIcon color='secondary' size='small' />
                            </IconButton>
                        </IconLink>

                        <IconLink href='https://www.facebook.com/profile.php?id=100092552027797'>
                            <IconButton>
                                <FacebookIcon color='secondary' size='small' />
                            </IconButton>
                        </IconLink>
                    </Stack>
                </Box>
                
                <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={isSmallScreen ? 2 : 3} styles={{ padding: theme.spacing(2, 0) }} alignItems='center'>
                    <Paragraph color='secondary.main'>Terms and Conditions</Paragraph>

                    <Divider color='secondary.main' flexItem />

                    <Paragraph color='secondary.main'>Privacy Policy</Paragraph>

                    <Divider color='secondary.main' flexItem/>

                    <Paragraph color='secondary.main'>Accessibility</Paragraph>
                </Stack>
            </Stack>
        </Box>
    )
}
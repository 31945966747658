import MuiContainer from '@mui/material/Container';
import { SxProps } from '@mui/system';

import { useScreenSize } from '../../theme/breakpoints';

export type ContainerBaseProps = {
    maxWidth?: string;
    styles?: SxProps;
    children: JSX.Element[] | JSX.Element
}

type ContainerProps = ContainerBaseProps & {
}

export default function Container (props: ContainerProps) {
    const { styles, maxWidth } = props;
    const isSmallScreen = useScreenSize('sm');

    return (
        <MuiContainer 
            sx={{
                maxWidth: maxWidth || isSmallScreen ? '100vw' : '1200px',
                ...styles
            }}>
            {props.children}
        </MuiContainer>
    )
}
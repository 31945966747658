import { useState } from 'react';

import Box from '../../components/containers/Box';
import Container from '../../components/containers/Container';
import Header2 from '../../components/text/Header2';
import Grid from '../../components/grids/Grid';
import Accordion from '../../components/accordion/Accordion';
import AccordionSummary from '../../components/accordion/AccordionSummary';
import AccordionDetails from '../../components/accordion/AccordionDetails';
import DownArrowIcon from '../../components/icons/DownArrowIcon';
import RightArrowIcon from '../../components/icons/RightArrowIcon';

import Header3 from '../../components/text/Header3';
import Paragraph from '../../components/text/Paragraph';
import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';

export type FAQSection = {
    id: string;
    summary: string;
    details: string;
}

type ColorOpts = 'inherit' | 'primary' | 'secondary';

type FAQProps = {
    faqs: FAQSection[];
    backgroundColor: string;
    color: ColorOpts;
}

export default function FAQ (props: FAQProps) {
    const { faqs, backgroundColor, color } = props;
    const isSmallScreen = useScreenSize('sm');

    return (
        <Box styles={{ backgroundColor: backgroundColor, padding: theme.spacing(isSmallScreen ? 5 : 6, 0) }}>
            <Container>
                <Header2 styles={{ marginLeft: '20px', marginBottom: '20px' }} color={color}>FAQs</Header2>
            </Container>

            <Container>

                {
                    faqs.map((faq: FAQSection, i: number) => (
                        <AccordionFAQ 
                            key={i} 
                            index={i} 
                            faq={faq}
                            color={color}
                            backgroundColor={backgroundColor}
                        />
                    ))
                }
            </Container>
        </Box>
    )
}

type AccordionFAQProps = {
    faq: FAQSection;
    index: number;
    color: ColorOpts;
    backgroundColor: string;
}

function AccordionFAQ (props: AccordionFAQProps) {
    const { index, faq, color, backgroundColor } = props;
    const [ isExpanded, setIsExpanded ] = useState(false);
    const isFirst = index === 0;
    const isSmallScreen = useScreenSize('sm');

    return (
        <Accordion 
            key={faq.id}
            onChange={setIsExpanded}
            styles={{ borderTopWidth: isFirst ? '1px' : 0, borderColor: `${color}.main`, backgroundColor: backgroundColor }}>
            <AccordionSummary styles={{ backgroundColor: backgroundColor }}>
                <Grid styles={{ flexWrap: 'nowrap', margin: theme.spacing(isSmallScreen ? 2 : 3, 0) }} justifyContent='space-between' alignItems='center'>
                    <Header3 
                        styles={{ lineHeight: 2.2 }} 
                        color={`${color}.main`}>
                        {faq.summary}
                    </Header3>

                    {
                        isExpanded
                            ? <RightArrowIcon color={color} size='large' />
                            : <DownArrowIcon color={color} size='large' />
                    }
                </Grid>

            </AccordionSummary>

            <AccordionDetails styles={{ backgroundColor: backgroundColor }}>
                <Paragraph styles={{ padding: theme.spacing(3, 0), whiteSpace: 'pre-line' }} color={`${color}.main`}>
                    {faq.details}
                </Paragraph>
            </AccordionDetails>
        </Accordion>
    )
}
import { SxProps } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';

type AccordionProps = {
    children: JSX.Element | JSX.Element[];
    styles?: SxProps;
    onChange: (isExpanded: boolean) => void
}

export default function Accordion (props: AccordionProps) {
    const { onChange, styles } = props;

    return (
        <MuiAccordion
            disableGutters
            onChange={(_event: React.SyntheticEvent, expanded: boolean) => onChange(expanded)}
            elevation={0}
            square
            sx={{ 
                backgroundColor: 'primary.main', 
                borderStyle: 'solid', 
                borderColor: 'secondary.main', 
                borderWidth: '0 0 1px 0',
                ...styles
            }}>
            {props.children}
        </MuiAccordion>
    )
}
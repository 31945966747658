import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom'

import Menus from '../../components/menus/Menus';

import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';

import PricingService from '../../services/pricing/PricingService';
import { Group } from '../../services/pricing/groups';

export default function Pricing () {
    const isSmallScreen = useScreenSize('sm')
    const params = useParams()
    const menu = params.menuName as Group['identifier'] || 'memberships';

    return (
        <Box
            sx={{ 
                backgroundColor: 'primary.main',
                minHeight: '100vh',
                paddingTop: isSmallScreen ? '20px' : '75px',
                paddingBottom: theme.spacing(isSmallScreen ? 4 : 5)
            }}>
            <Container>
                <Menus 
                    title='Pricing'
                    menu={menu}
                    groups={PricingService.getGroups()}
                />
            </Container>
        </Box>
    )
}
import MindBodyLink from './MindBodyLink';

interface Props {
    dataServiceId: string
}

export default function MindBodyContractLink (props: Props) {
    return (
        <MindBodyLink
            dataLinkClass='healcode-contract-text-link'
            dataType='contract-link'
            dataInnerHtml='Buy Now'
            dataServiceId={props.dataServiceId}
        />
    )
}
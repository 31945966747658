import { useState } from 'react';

import Drawer from '../../components/drawer/Drawer';
import MenuIcon from '../../components/icons/MenuIcon';
import IconButton from '../../components/buttons/IconButton';
import TextLink from '../../components/links/TextLink';
import Navigation from '../../services/navigation'

export default function SmallScreenMenu () {
    const [ isOpen, setOpen ] = useState(false);

    function toggleOpen (isOpen: boolean) {
        setOpen(isOpen);
    }

    return (
        <>
            <IconButton onClick={() => toggleOpen(true)}>
                <MenuIcon color='secondary' size='large' />
            </IconButton>

            <Drawer
                isOpen={isOpen}
                anchor='right'
                onClose={() => toggleOpen(false)}
                menuStyles={{ backgroundColor: 'primary.main', minWidth: '50%', paddingTop: '15px' }}>
                {
                    Navigation.getAllNavItems().map((page, i) => (
                        <TextLink
                            key={i}
                            styles={{ backgroundColor: 'primary.main', padding: `15px 10px 15px ${page.subItem ? '50px' : '10px'}` }}
                            color='secondary.main'
                            hoverColor='highlight.main'
                            capitalize
                            target={page.external ? '_about' : '_self'}
                            href={page.url}>
                            {page.title}
                        </TextLink>
                    ))
                }
            </Drawer>
        </>
    )
}
import Image from '../../components/images/Image';
import { useScreenSize } from '../../theme/breakpoints';

const ghg_horizontal_logo_path = 'ghg_horizontal_logo.svg';
const ghg_small_logo_path = 'ghg_small_logo_highlight.svg';

export default function Logo () {
    const isSmallScreen = useScreenSize('sm');

    return isSmallScreen
        ? <Image styles={{ height: '55px' }} src={ghg_small_logo_path} />
        : <Image styles={{ width: '360px' }} src={ghg_horizontal_logo_path} />
}
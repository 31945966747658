import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack'


import MindBodyScheduleWidget from '../../components/widgets/mindbody/MindbodyScheduleWidget';

import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints'; 

export default function Schedule () {
    const isSmallScreen = useScreenSize('sm')

    return (
        <Container 
            sx={{ 
                paddingTop: '25px', 
                marginBottom: '25px',
                minHeight: '100vh',
            }}>
            <Typography 
                variant='h1' 
                textAlign='center'
                sx={{ 
                    marginTop: theme.spacing(isSmallScreen ? 2 : 3),
                    marginBottom: theme.spacing(isSmallScreen ? 3 : 4)
                }}>
                Schedule
            </Typography>

            <Stack direction='column' spacing={3}>
                <Typography variant='h3'>
                    Hours
                </Typography>

                <Box>
                    <Typography variant='h4' fontWeight={700}>
                        Open Hours
                    </Typography>

                    <Typography variant='body1'>
                        Everyday, 5am-12am
                    </Typography>
                </Box>

                <Box>
                    <Typography variant='h4' fontWeight={700}>
                        Staffed Hours
                    </Typography>

                    <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                        Bouldering and under-18 access limited to staffed hours
                    </Typography>

                    <List>
                        <ListItem disablePadding>
                            <ListItemText primary="Monday" secondary="3-7pm" />
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemText primary="Tuesday" secondary="7-11am and 3-7pm" />
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemText primary="Wednesday" secondary="3-7pm" />
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemText primary="Thursday" secondary="7-11am" />
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemText primary="Friday" secondary="3-7pm" />
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemText primary="Saturday" secondary="10am-2pm" />
                        </ListItem>
                    </List>
                </Box>

            </Stack>

            <Box sx={{ marginTop: '25px' }}>
                <MindBodyScheduleWidget />
            </Box>
        </Container>
    )
}
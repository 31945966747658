import { mediaQueryMaxWidth } from './breakpoints';

const typography = {
    fontFamily: 'Menlo, Lato',
    letterSpacing: '0.1em',
    h1: {
        fontSize: '3rem',
        [mediaQueryMaxWidth('md')]: {
            fontSize: '1.75rem'
        }
    },
    h2: {
        fontSize: '2rem',
        [mediaQueryMaxWidth('md')]: {
            fontSize: '1.5rem'
        }
    },
    h3: {
        fontSize: '1.5rem',
        [mediaQueryMaxWidth('md')]: {
            fontSize: '1.3rem'
        }
    },
    h4: {
        fontSize: '1.1rem',
        [mediaQueryMaxWidth('md')]: {
            fontSize: '1rem'
        }
    },
    h5: {

    },
    h6: {

    },
    body1: {
        fontSize: '12px'
    },
    span: {
    }
}

export default typography;
import TextBase, { TextProps } from './TextBase';

type Header1Props = TextProps & {

}

export default function Header1 (props: Header1Props) {
    const { styles } = props;
    
    return (
        <TextBase {...props} styles={{ letterSpacing: '0.1em', ...styles }} variant='h1'>
            {props.children}
        </TextBase>
    )
}
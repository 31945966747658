import FAQ, { FAQSection } from '../../sections/faq/FAQ';

const faqs: FAQSection[] = [
    {
        id: '1',
        summary: 'WHAT CLASSES DO YOU OFFER?',
        details: `We work with local fitness instructors to offer a diverse range of classes for every age, ability level, and fitness goals. Our current class offerings include:

        • Functional fitness 
        • HIIT
        • Dance 
        • Yoga
        • Strength training
        • Boxing

        We will also provide workshops for people wanting to focus on self-defense, weight lifting, sports agility, and more. We’re determined to offer classes and programs that reflect what the community wants. Please send us feedback if you are interested in something we have not yet added to our offerings.
        `
    },
    {
        id: '2',
        summary: 'WHAT EQUIPMENT DOES THE GYM HAVE?',
        details: `At the Goat House, we are all about providing top-notch fitness equipment that caters to a diverse range of interests and goals. We believe that everyone should have access to the tools they need to achieve their fitness aspirations, which is why we are committed to offering a variety of high-quality equipment.

        For cardio enthusiasts, we have everything from treadmills and ellipticals, to bikes, assault bikes and rowing machines. Our weight lifting equipment will feature squat racks, barbells, benches, free weights, kettlebells, and more. For those looking to mix things up, we will have plenty of unique items to choose from, including battle ropes, push sleds, heavy bags, jump ropes, resistance bands, slam balls, pull-up bars, and even a bouldering wall!

        The Goat House Gym is for and only made possible by the community. That’s why we are always open to feedback from our members, so we can ensure that we're providing the equipment and tools that best meet your needs. We believe everyone should be able to reap the physical and mental health benefits that fitness offers, and we are committed to making that a reality for our community.
        `
    },
    {
        id: '3',
        summary: 'HOW DO I ACCESS THE GYM?',
        details: `The Goat House is situated within the newly constructed brewery warehouse building. Access our space through the sole exterior door located on the south side of the structure.

        The gym is open to members and drop ins every day from 5am to 12am. 
        
        We offer staffed hours most days of the week for your convenience. During staffed hours, the door will remain open and you can check in with the person at the front desk. The bouldering wall is closed and people under 18 cannot use the gym outside of staffed hours. Please see our schedule for our most current staffed hours. 
        
        When the gym isn't staffed, the front door will be locked and access is managed through our security software, Brivo. Upon signing up for a Goat House Gym membership, you'll get an email from Brivo with instructions on how to download an app and access the space. You can also talk to us in person about setting up a 4-digit pin code to unlock the front door.`
    },
    {
        id: '4',
        summary: 'WHY ARE YOU CALLED THE GOAT HOUSE?',
        details: `The Goat House takes inspiration from two magnificent mountains in our locality: West Goat and East Goat. Our gym facility represents West Goat, the highest peak in the Anaconda Pintler mountain range, while East Goat, located adjacent to West Goat, is the name of our fitness studio. By using these names, we not only celebrate the beauty of our region but also motivate our members to strive for excellence.

        Our goal at The Goat House is to create a supportive and empowering environment where individuals can build strength, increase their fitness level, and unleash their full potential. Whether you prefer to take a yoga class at East Goat or lift weights at West Goat, we're committed to helping you achieve your fitness goals and become the Greatest Of All Time.
        `
    }
]

export default function GeneralFAQ () {
    return (
        <FAQ faqs={faqs} backgroundColor='primary.main' color='secondary' />
    )
}
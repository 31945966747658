import MuiArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { IconProps } from './IconBase';

type DownArrowIcon = IconProps & {

}

export default function DownArrowIcon (props: IconProps) {
    const { color, size } = props;

    return (
        <MuiArrowDownIcon color={color} fontSize={size} />
    );
}
import Stack from '@mui/material/Stack';
import TextLink from '../../components/links/TextLink';
import Navigation from '../../services/navigation';

export default function DefaultMenu () {
    return (
        <Stack direction='row' spacing={2}>
            {
                Navigation.getPrimaryNavItems().map(page => (
                    <TextLink
                        styles={{ backgroundColor: 'primary.main', padding: '10px 10px' }}
                        color='secondary.main'
                        hoverColor='highlight.main'
                        capitalize
                        target={page.external ? '_about' : '_self'}
                        href={page.url}>
                        {page.title}
                    </TextLink>
                ))
            }
        </Stack>
    )
}
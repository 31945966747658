export default function loadShopify (callback: () => void) {
    const existingScript = document.getElementById('shopify');

    if (!existingScript) {
        const script = document.createElement('script');

        script.src = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';
        script.id = 'shopify';
        script.async = true;

        (document.getElementsByTagName('head')[0]).appendChild(script);

        script.onload = () => {
            if (callback) callback();
        };
    }

    if (existingScript && callback) callback();
};
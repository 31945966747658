import { SxProps } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

type Anchor = 'right';

type DrawerProps = {
    children: JSX.Element | JSX.Element[];
    isOpen: boolean;
    onClose: () => void;
    anchor: Anchor;
    menuStyles?: SxProps;
}

export default function Drawer (props: DrawerProps) {
    const { isOpen, onClose, anchor, menuStyles } = props;

    return (
        <MuiDrawer
            open={isOpen}
            onClose={onClose}
            anchor={anchor}
            variant='temporary'
            PaperProps={{ sx: menuStyles }}>
            {props.children}
        </MuiDrawer>
    )
}
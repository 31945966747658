import { SxProps } from '@mui/material';
import { ReactElement } from 'react';
import styled from 'styled-components';

const Div = styled.div`
    background-color: ${(props) => props.theme.palette.highlight.main};
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

type CalloutProps = {
    children: ReactElement | ReactElement[];
}

export default function Callout (props: CalloutProps) {

    return (
        <Div>
            <span style={{ fontSize: '24px', marginRight: '15px' }}>🎉</span>
            {props.children}
        </Div>
    )
} 
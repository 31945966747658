import TextBase, { TextProps } from './TextBase';

type Header3Props = TextProps & {

}

export default function Header3 (props: Header3Props) {
    const { styles } = props;
    
    return (
        <TextBase {...props} styles={{ letterSpacing: '0.1em', ...styles }} variant='h4'>
            {props.children}
        </TextBase>
    )
}
import MuiMenuIcon from '@mui/icons-material/Menu';

import { IconProps } from './IconBase';

type MenuIconProps = IconProps & {
    
}

export default function MenuIcon (props: MenuIconProps) {
    const { color, size } = props;

    return (
        <MuiMenuIcon color={color} fontSize={size} />
    )
}
import MuiBox from '@mui/material/Box';

import { ContainerBaseProps } from './Container';
import { useScreenSize } from '../../theme/breakpoints';

type BoxProps = ContainerBaseProps & {
}

export default function Box (props: BoxProps) {
    const { 
        styles,
        maxWidth 
    } = props;
    return (
        <MuiBox 
            maxWidth={maxWidth} 
            sx={styles}>
            {props.children}
        </MuiBox>
    )
}
import { SxProps } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

type AccordionSummaryProps = {
    children: JSX.Element | JSX.Element[];
    styles?: SxProps;
}

export default function AccordionSummary (props: AccordionSummaryProps) {
    return (
        <MuiAccordionSummary sx={{ borderWidth: 0, ...props.styles }}>
            {props.children}
        </MuiAccordionSummary>
    )
}
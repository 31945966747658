import styled from 'styled-components';

interface ContainedButtonProps {
    $backgroundColor: string;
    $color?: string;
}

const ContainedButton = styled.button<ContainedButtonProps>`
    border-radius: 8px;
    border: none;
    background-color: ${props => props.theme.palette[props.$backgroundColor].main};
    color: ${props => props.$color ? props.theme.palette[props.$color].main : 'black'};
    text-transform: uppercase;
    font-weight: 700;
    padding: 15px 25px;

    &:hover {
        cursor: pointer;
        box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
    }

    &:visited {
        text-decoration: ${props => props.$color ? props.theme.palette[props.$color].main : 'black'}
    }
`

export default ContainedButton;
import { Fragment } from "react";

import { useScreenSize } from '../../theme/breakpoints';

import './flodeskform.css';

export default function FlodeskForm () {
    const formData = {
        formId: '64a7256c349daf2cf867be4d',
        containerEl: '#fd-form-64a7256c349daf2cf867be4d'
    } 
    // @ts-ignore
    window.fd('form', formData);

    const isSmallScreen = useScreenSize('sm');

    return (
        <Fragment>
            <div id="fd-form-64a7256c349daf2cf867be4d" style={{ width: isSmallScreen ? '75vw' : '400px' }}></div>
        </Fragment>
    )
}
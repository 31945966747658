import theme from './theme';

const spacing = [0, 4, 8, 16, 32, 64, 128];

// provide a factor that might change depending on the spacing
// responsive spacing
// mui spacing
// use in spacing property
// use in custom spacing

export default spacing;
import TextBase, { TextProps } from './TextBase';

type ParagraphProps = TextProps & {
}

export default function Paragraph (props: ParagraphProps) {
    return (
        <TextBase {...props} variant='body1'>
            {props.children}
        </TextBase>
    )
}
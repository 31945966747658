import React, { useState, useEffect } from 'react';

import './mindbodylink.css';
import loadMindBody from './loadMindBody';

export interface MindBodyLinkData {
    dataServiceId: string
}

type MindBodyLinkProps = MindBodyLinkData & {
    dataLinkClass: string;
    dataType: 'contract-link' | 'pricing-link';
    dataInnerHtml: string;
}

export default function MindBodyLink (props: MindBodyLinkProps) {
    const { 
        dataLinkClass,
        dataServiceId, 
        dataInnerHtml, 
        dataType,
    } = props;

    const [ isMindBody, setMindBody ] = useState(false);

    useEffect(() => {
        loadMindBody(() => setMindBody(true));
    })

    return isMindBody ? (
        // @ts-ignore
        <healcode-widget
            data-link-class={`custom-mindbody-link ${dataLinkClass || ''}`}
            data-version="0.2" 
            data-bw-identity-site="true" 
            data-site-id='114374'
            data-mb-site-id='5732490'
            data-type={dataType}
            data-inner-html={dataInnerHtml}
            data-service-id={dataServiceId}
        />
    ) : null
}
import { Fragment } from 'react';

import Header from '../../sections/header/Header';
import Container from '../../components/containers/Container';
import Box from '../../components/containers/Box';
import Header1 from '../../components/text/Header1';
import Header2 from '../../components/text/Header2';
import Paragraph from '../../components/text/Paragraph';
import Span from '../../components/text/Span';
import LighteningIcon from '../../components/icons/LightningIcon';
import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';
import FounderCard from './FounderCard';
import KettlebellIcon from '../../components/icons/KettlebellIcon';
import DumbellIcon from '../../components/icons/DumbellIcon';
import GloveIcon from '../../components/icons/GloveIcon';


export default function About () {
    const isSmallScreen = useScreenSize('sm');

    return (
        <Box
            styles={{ 
                backgroundColor: 'primary.main',
                minHeight: '100vh',
                paddingTop: '25px',
                paddingBottom: theme.spacing(isSmallScreen ? 4 : 5)
            }}>
            <Container>
                <Header1 
                    textAlign='center'
                    color='secondary.main'
                    styles={{
                        marginTop: theme.spacing(isSmallScreen ? 2 : 3),
                        marginBottom: theme.spacing(isSmallScreen ? 2 : 3)
                    }}>
                    About Us
                </Header1>

                <Paragraph 
                    color={'secondary.main'}
                    styles={{ 
                        fontSize: isSmallScreen ? '24px' : '32px',
                        margin: theme.spacing(isSmallScreen ? 4 : 5, 0),
                        letterSpacing: 0,
                        lineHeight: 1
                    }}>
                    We are <Span styles={{ fontFamily: 'Almonde', fontSize: '38px', verticalAlign: 'middle' }} fontWeight={700} color='highlight.main'>The Goat House</Span>. A community-driven gym and fitness studio, providing people of all ages and abilities with the tools to find complete physical, mental, and social well-being.
                </Paragraph>
                
                <Box styles={{ display: 'flex', justifyContent: 'center' }}>
                    <LighteningIcon width={150} height={150} />
                </Box>

                <Header2
                    color='secondary.main'
                    textAlign={isSmallScreen ? 'center' : 'left'}
                    styles={{
                        margin: theme.spacing(isSmallScreen ? 4 : 5, 0)
                    }}>
                    Meet the Founders
                </Header2>

                <FounderCard
                    name='Camryn'
                    description='Camryn is focused on creating a space for people of all ages and fitness levels to develop healthy habits, as well as providing an opportunity for local fitness professionals to connect with new clientele. Her commitment to spreading her message of strength and confidence through fitness has garnered her a loyal client-base and glowing feedback from her students. At The Goat House, Camryn will be the lead instructor, in charge of equipment and programming.'
                    photoUrl='camryn.png'
                />

                <FounderCard
                    name='Kaz'
                    description={`Kaz's strong commitment to the community and her love of fitness led her to co-found The Goat House. Kaz has called Philipsburg home since 2014 and loves the people and the opportunities for year-round outdoor activities. As a former waterpolo player and swimmer, Kaz believes fitness should be fun and accessible to everyone. At The Goat House, Kaz is in charge of all things administrative, including memberships.`}
                    photoUrl='kaz.png'
                />

                <Box styles={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: theme.spacing(isSmallScreen ? 4 : 5, isSmallScreen ? 4 : 5) }}>
                    <KettlebellIcon width={150} height={150} />
                    <DumbellIcon width={150} height={150} />
                    <GloveIcon width={150} height={150} />
                </Box>

                <Header2
                    color='secondary.main'
                    textAlign={isSmallScreen ? 'center' : 'left'}
                    styles={{ marginBottom: theme.spacing(isSmallScreen ? 4 : 5)}}>
                    The Space
                </Header2>

                <Paragraph color='secondary.main'>
                    {`The Goat House is located inside The Depot, the new brewery warehouse building on Sunshine Road. Our unit takes up the SW corner of the downstairs of the building. At just shy of 2400 square feet, we offer a multi-use fitness studio, a bouldering wall, cardio machines, and strength training equipment.`}
                </Paragraph>
            </Container>
        </Box>
    )
}
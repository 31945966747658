import Box from '../../components/containers/Box';
import Container from '../../components/containers/Container';
import Grid from '../../components/grids/Grid';
import Logo from './Logo';
import IconLink from '../../components/links/IconLink';
import DefaultMenu from '../navigation/DefaultMenu';
import SmallScreenMenu from '../navigation/SmallScreenMenu';
import { useScreenSize } from '../../theme/breakpoints';

export default function Header () {
    const isSmallScreen = useScreenSize('md');

    return (
        <Box styles={{ backgroundColor: 'primary.main' }}>
            <Container>
                <Grid styles={{ height: '95px' }} justifyContent='space-between' alignItems='center'>
                    <IconLink href='/' target='_self'>
                        <Logo />
                    </IconLink>

                    {
                        isSmallScreen
                            ? <SmallScreenMenu />
                            : <DefaultMenu />
                    }
                </Grid>
            </Container>
        </Box>
    )
}
import { SxProps } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

type AccordionDetailsProps = {
    children: JSX.Element;
    styles?: SxProps;
}

export default function AccordionDetails (props: AccordionDetailsProps) {
    return (
        <MuiAccordionDetails sx={{ borderWidth: 0, ...props.styles }}>
            {props.children}
        </MuiAccordionDetails>
    )
}
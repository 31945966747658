import { ReactComponent as GloveSvg } from './svgs/glove.svg';

type GloveIconProps = {
    width: number;
    height: number;
}


export default function GloveIcon (props: GloveIconProps) {
    const { width, height } = props;

    return (
        <GloveSvg width={width} height={height} />
    )
} 
import MuiIconButton from '@mui/material/IconButton';

import { ButtonProps } from './ButtonBase';

type IconButtonProps = ButtonProps & {
}

export default function IconButton (props: IconButtonProps) {
    return (
        <MuiIconButton {...props }>
            {props.children}
        </MuiIconButton>
    )
}
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import Header1 from '../../components/text/Header1';
import Header3 from '../../components/text/Header3';

import { mediaQueryMaxWidth, useScreenSize } from '../../theme/breakpoints';

type HeroSectionProps = {
    height: string;
    heroImagePath: string;
    imageCover?: boolean;
    cardWidth: string;
    titleText?: string;
    descriptionText?: string;
    buttonText?: string;
    buttonHref?: string;
    backgroundPosition?: string;
}

export default function HeroSection (props: HeroSectionProps) {
    const { 
        height, 
        heroImagePath,
        imageCover = false,
        cardWidth,
        buttonHref,
        titleText, 
        descriptionText, 
        buttonText,
        backgroundPosition
    } = props;
    const isMediumScreen = useScreenSize('md');

    return (
        <Stack
            direction='column'
            alignItems={isMediumScreen ? 'flex-start' : 'flex-end'}
            justifyContent={isMediumScreen ? 'flex-end' : 'center'}
            sx={{ 
                height: height, 
                width: '100vw', 
                backgroundImage: `url(${heroImagePath})`,
                backgroundSize: isMediumScreen || !!imageCover ? 'cover' : `calc(100vw - ${cardWidth})`,
                backgroundPosition: backgroundPosition || 'none',
            }}
            spacing={isMediumScreen ? 2 : 3}>
            <Stack
                justifyContent='center'
                sx={{
                     width: isMediumScreen ? '100%' : cardWidth, 
                     height: isMediumScreen ? '25%' : '100%' ,
                     padding: isMediumScreen ? '10px' : '40px',
                     boxSizing: 'border-box',
                     backgroundColor: isMediumScreen || imageCover ? 'none' : 'white' 
                }}
                spacing={isMediumScreen ? 2 : 3}>
                {
                    !!titleText &&
                    <Header1 
                        color='primary.main'
                        styles={{ 
                            fontWeight: 700, 
                            lineHeight: 1.2, 
                            letterSpacing: '0em', 
                            textAlign: isMediumScreen ? 'left' : 'right'
                        }}>
                        {titleText}
                    </Header1>
                }

                <Stack 
                    direction={isMediumScreen ? 'row' : 'column'}
                    alignItems={isMediumScreen ? 'center' : 'flex-end'}
                    justifyContent={isMediumScreen ? 'space-between' : ''}
                    spacing={isMediumScreen ? 0 : 3}>
                    {
                        !!descriptionText &&
                        <Header3
                            color='primary.main'
                            styles={{ 
                                fontWeight: 700, 
                                lineHeight: 1.2, 
                                letterSpacing: '0em',
                                textAlign: isMediumScreen ? 'left' : 'right'
                            }}>
                            {descriptionText}
                        </Header3>
                    }

                    {
                        !!buttonHref && buttonText &&
                        <Button
                            id="basic-button"
                            variant='outlined'
                            href={buttonHref}
                            color='primary'
                            size='large'
                            sx={{ 
                                backgroundColor: 'highlight.main', 
                                fontWeight: 700,
                                fontSize: '16px',
                                textWrap: 'nowrap',
                                border: 'none',
                                '&:hover': {
                                    backgroundColor: 'highlight.main',
                                    border: 'none'
                                }
                            }}>
                            {buttonText}
                        </Button>
                    }
                </Stack>
            </Stack>
        </Stack>
    )
}
import groups, { Group } from './groups'
import categories, { Category } from './categories'
import offerings, { Offering } from './offerings'

type GroupsMap = { 
    [key in Group['identifier']]: Group 
}

const groupsByIdentifier: GroupsMap = groups.reduce((acc, group: Group) => {
    acc[group.identifier] = group
    
    return acc
}, {} as GroupsMap)

export default class PricingService {
    public static getGroups (): Group[] {
        return groups
    }

    public static getCategories (): Category[] {
        return categories
    }

    public static getGroupByIdentifier (identifier: Group['identifier']): Group {
        return groupsByIdentifier[identifier]
    }

    public static getCategoriesByGroup (identifier: Group['identifier']): Category[] {
        return categories.filter(category => category.groups.includes(identifier))
    }

    public static getOfferingsByCategory (identifier: Category['identifier']): Offering[] {
        return offerings.filter(offering => offering.categories.includes(identifier))
    }
}
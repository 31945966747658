import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

import ContainedButton from '../../styled-components/ContainedButton';
import { useScreenSize } from '../../theme/breakpoints';
import theme from '../../theme/theme';

export default function NotFound () {
    const isSmallScreen = useScreenSize('sm')

    return (
        <Box 
            sx={{ 
                backgroundColor: 'primary.main', 
                minHeight: '100vh',
                paddingTop: '25px',
                paddingBottom: theme.spacing(isSmallScreen ? 4 : 5)
            }}>
            <Container>
                <Stack direction='column' alignItems='center' spacing={4}>
                    <Typography 
                        variant='h1' 
                        color='secondary.main'
                        textAlign='center'
                        sx={{
                            marginTop: theme.spacing(isSmallScreen ? 2 : 3),
                            marginBottom: theme.spacing(isSmallScreen ? 2 : 3)
                        }}>
                        Whoops! We can't find that page.
                    </Typography>

                    <Typography variant='h4' color='secondary.main' textAlign='center'>
                        404 - Not Found
                    </Typography>

                    <Box 
                        component='img' 
                        src='mountain-goat-wildlife-nature-looking-preview.jpeg'
                        sx={{
                            maxWidth: '90vw',
                            width: '450px',
                            borderRadius: '15px'
                        }}
                    />                

                    <ContainedButton $color='highlight' $backgroundColor='highlight'>
                        <Link to={'/'} style={{ textDecoration: 'none' }}>
                            <Typography variant='h4' color='primary.main' fontWeight='700'>Go to the Home page</Typography>
                        </Link>
                    </ContainedButton>
                </Stack>
            </Container>
        </Box>
    )
}
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as YoutubeSvg } from './svgs/youtube.svg';

type YoutubeIconProps = {
    color: 'primary' | 'secondary';
    size: 'small' | 'medium';
}

export default function YoutubeIcon (props: YoutubeIconProps) {
    const { color, size } = props;

    return (
        <SvgIcon component={YoutubeSvg} color={color} fontSize={size} inheritViewBox />
    )
}
import { useState, useEffect } from 'react'

import loadMindBody from './loadMindBody'

export default function MindBodyScheduleWidget () {
    const [ isMindBody, setMindBody ] = useState(false);

    useEffect(() => {
        loadMindBody(() => setMindBody(true));
    })

    return isMindBody ? (
        // @ts-ignore
        <healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="5e2078795e57" data-widget-version="1" ></healcode-widget>
    ) : null
}
import TextBase, { TextProps } from './TextBase';

type Header2Props = TextProps & {

}

export default function Header2 (props: Header2Props) {
    const { styles } = props;

    return (
        <TextBase {...props} styles={{ letterSpacing: '0.1em', ...styles }} variant='h2'>
            {props.children}
        </TextBase>
    )
}
import { ReactComponent as KettlebellSvg } from './svgs/kettlebell.svg';

type KettlebellIconProps = {
    width: number;
    height: number;
}


export default function KettlebellIcon (props: KettlebellIconProps) {
    const { width, height } = props;

    return (
        <KettlebellSvg width={width} height={height} />
    )
} 
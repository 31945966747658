import MindBodyLink from './MindBodyLink';

type Props = {
    dataServiceId: string
}

export default function MindBodyPricingLink (props: Props) {
    return (
        <MindBodyLink
            dataLinkClass='healcode-pricing-option-text-link'
            dataType='pricing-link'
            dataInnerHtml='Buy Now'
            dataServiceId={props.dataServiceId}
        />
    )
}
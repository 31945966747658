import MuiStack from '@mui/material/Stack';

import { ContainerBaseProps } from './Container';

type StackProps = ContainerBaseProps & {
    direction: 'row' | 'column' | 'row-reverse' | 'column-reverse';
    justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly';
    alignItems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
    spacing?: number;
}

export default function StackProps (props: StackProps) {
    const { 
        styles,
        maxWidth,
        direction,
        justifyContent,
        alignItems,
        spacing
    } = props;

    return (
        <MuiStack
            maxWidth={maxWidth}
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
            spacing={spacing}
            sx={styles}>
            {props.children}
        </MuiStack>
    )
}
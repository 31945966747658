import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import Header from '../../sections/header/Header';

import { useScreenSize } from '../../theme/breakpoints';
import theme from '../../theme/theme';

const address = '1140 Sunshine Road\nPhilipsburg, MT 59858'
const email = 'info@goathousegym.com'
const phoneNumber = '(406)-201-6024'

export default function Contact () {
    const isSmallScreen = useScreenSize('sm');

    return (
        <Container sx={{ paddingTop: '45px', paddingBottom: theme.spacing(isSmallScreen ? 4 : 5) }}>
            <Stack direction={{ md: 'column', lg: 'row-reverse' }} spacing={6} sx={{ width: '100%' }}>
                <Stack direction='column' sx={{ width: { sm: '100%', md: '50%' }}}>
                    <Typography variant='h1'>Contact Us</Typography>

                    <Stack sx={{ paddingTop: '25px', paddingBottom: '25px' }} direction='column' spacing={3}>
                        <Typography variant='h4'><span style={{ fontWeight: 700 }}>Email us:{' '}</span>
                            <Link href={`mailto:${email}`}>{email}</Link>
                        </Typography>

                        <Typography variant='h4'><span style={{ fontWeight: 700 }}>Call us:{' '}</span>
                            <Link href={`tel:${phoneNumber}`}>{phoneNumber}</Link>
                        </Typography>
                    </Stack>
                </Stack>

                <Stack direction='column' alignItems='center' spacing={3} sx={{ width: { sm: '100%', md: '50%' }}}>
                    <Box
                        sx={{ height: '65vh', width: '100%' }}>
                        <iframe 
                            width="100%" 
                            height="100%" 
                            style={{ border: 0 }} 
                            loading="lazy"
                            src="https://www.google.com/maps/embed/v1/place?q=place_id:EiwxNDAwIFN1bnNoaW5lIFJkLCBQaGlsaXBzYnVyZywgTVQgNTk4NTgsIFVTQSIxEi8KFAoSCUNoAfrddFxTEVsmUwPf7mZFEPgKKhQKEglDaAH63XRcUxHDhhcoj6EWvA&key=AIzaSyBq_Rn_z3I2paKNcjTqsPlWnaEZxNkvkuA">    
                        </iframe>
                    </Box>

                    <Typography variant='h3' fontWeight={700} whiteSpace='pre-wrap' textAlign='center'>
                        <Link href='https://maps.app.goo.gl/iwfxqYB2Je44barm6'>{address}</Link>
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    )
}
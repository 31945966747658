import { createTheme } from '@mui/material/styles';

import typography from './typography';
import colors from './colors';
import breakpoints from './breakpoints';
import spacing from './spacing';

const theme = createTheme({
    typography: typography,
    palette: colors,
    breakpoints: breakpoints,
    spacing: spacing
});

export default theme;
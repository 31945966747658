import { useState } from 'react';

import Box from '../../components/containers/Box';
import Container from '../../components/containers/Container';
import Stack from '../../components/containers/Stack';
import Header3 from '../../components/text/Header3';
import Paragraph from '../../components/text/Paragraph';
import TextLink from '../../components/links/TextLink';
import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';
import FlodeskForm from '../../components/flodesk/FlodeskForm';

import axios from 'axios';

export default function JoinTheHerd () {
    const [ email, updateEmail ] = useState<string>('');
    const isSmallScreen = useScreenSize('sm');

    async function onSubmit (e: React.SyntheticEvent) {
        e.stopPropagation();

        try {
            await axios.get(`https://goathousegym.com/?email=${email}`)
        }
        catch (err) {
            console.log(err);
        }

        updateEmail('');
    }

    function onChange (e: React.ChangeEvent<any>) {
        e.stopPropagation();

        updateEmail(e.target.value);
    }

    return (
        <Box styles={{ backgroundColor: 'primary.main', paddingBottom: theme.spacing(isSmallScreen ? 4 : 5) }}>
            <Container>
                <Stack 
                    direction={isSmallScreen ? 'column' : 'row'}
                    justifyContent='space-between' 
                    alignItems='center'
                    spacing={3}>
                    <Stack 
                        styles={{ maxWidth: isSmallScreen ? '100%': '450px' }}
                        direction='column'
                        spacing={3}>
                        <Header3 styles={{ fontSize: '16px', fontWeight: 700 }} textAlign={isSmallScreen ? 'center' : 'left'} color='secondary.main'>WE ARE THE GOAT HOUSE</Header3>

                        <Paragraph color='secondary.main'>Our mission is to empower individuals of all ages and fitness levels to achieve their health and wellness goals through education, motivation, and support. We strive to create a welcoming and inclusive environment where everyone feels valued and supported on their fitness journey.</Paragraph>
                    </Stack>

                    <Stack
                        direction='column'
                        alignItems='center' spacing={3}>
                        <TextLink
                            href='https://www.instagram.com/goathousegym/'
                            styles={{
                                fontSize: '16px',
                                fontWeight: 700,
                                letterSpacing: '0.1em',
                            }}
                            hoverColor={'highlight.main'}
                            color='secondary.main'
                            underline>
                            #JOINTHEHERD
                        </TextLink>

                        <Box styles={{ width: isSmallScreen ? '100%' : '300px' }}>
                            <Paragraph color='secondary.main' textAlign='center'>Memberships are now open. Sign up for our email list to receive Goat House Gym updates!</Paragraph>
                        </Box>

                        <FlodeskForm />
                    </Stack> 
                </Stack>
            </Container>
        </Box>
    )
}
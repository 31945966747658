import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Widget from '../../components/widgets/Widget';

import { useScreenSize } from '../../theme/breakpoints';

import { Offering } from '../../services/pricing/offerings';

type Props = {
    item: Offering;
}

export default function MenuItemCard (props: Props) {
    const { item } = props;
    const { widget, widgetData } = item;
    const isSmallScreen = useScreenSize('sm');

    return (
        <Stack
            direction={isSmallScreen ? 'column' : 'row'}    
            alignItems={isSmallScreen ? 'flex-start' : 'center'}
            spacing={3}
            sx={{
                padding: '20px',
                borderWidth: '1px 0 0',
                borderColor: 'highlight.main',
                borderStyle: 'solid'
            }}>
            <Stack direction='column' spacing={3} sx={{ flexGrow: 1 }}>
                <Typography 
                    variant='h4'
                    color='secondary.main' 
                    maxWidth={isSmallScreen ? '100%' : '75%'}
                    fontWeight={700}>
                    {`${item.title} - `}
                    <Typography variant='span'>{item.price}</Typography>
                </Typography>
                <Typography variant='body1' color='secondary.main' maxWidth={isSmallScreen ? '100%' : '75%'} sx={{ whiteSpace: 'pre-line' }}>{item.description}</Typography>
            </Stack>
            
            <Widget widgetType={widget} widgetData={widgetData} />
        </Stack>
    )
}
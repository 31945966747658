export type Group = {
    identifier: 'memberships' | 'drop-ins' | 'kids-dance'
    title: string
}

const groups: Group[] = [
    {
        identifier: 'memberships',
        title: 'Memberships'
    },
    {
        identifier: 'drop-ins',
        title: 'Drop-ins'
    },
    {
        identifier: 'kids-dance',
        title: 'Kids Dance'
    }
]

export default groups
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as FacebookSvg } from './svgs/facebook.svg';

type FacebookIconProps = {
    color: 'primary' | 'secondary';
    size: 'small' | 'medium';
}

export default function FacebookIcon (props: FacebookIconProps) {
    const { color, size } = props;

    return (
        <SvgIcon component={FacebookSvg} color={color} fontSize={size} inheritViewBox />
    )
}
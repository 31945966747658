const colors = {
    primary: {
        main: 'rgba(28,28,28,1)',
        faded: 'rgba(50,50,50,1)'
    },
    secondary: {
        main: 'rgba(255,255,255,1)'
    },
    highlight: {
        main: 'rgba(210,241,62,1)'
    }
}

export default colors;
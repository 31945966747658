import { TrendingUpTwoTone } from "@mui/icons-material";

interface NavPage {
    title: string;
    url: string;
    external?: boolean;
    navItem: boolean;
    subItem: boolean;
}

const HOME_URL= '/'
export const PRICING_URL = '/pricing'
export const SCHEDULE_URL = '/schedule'

const pages: NavPage[] = [
    {
        title: 'Home',
        url: HOME_URL,
        navItem: false,
        subItem: false
    },
    {
        title: 'Pricing',
        url: PRICING_URL,
        navItem: true,
        subItem: false
    },
    {
        title: 'Memberships',
        url: `${PRICING_URL}/memberships`,
        navItem: true,
        subItem: true
    },
    {
        title: 'Drop-ins',
        url: `${PRICING_URL}/drop-ins`,
        navItem: true,
        subItem: true
    },
    {
        title: 'Kids Dance',
        url: `${PRICING_URL}/kids-dance`,
        navItem: true,
        subItem: true
    },
    {
        title: 'Schedule',
        url: SCHEDULE_URL,
        navItem: true,
        subItem: false
    },
    {
        title: 'Shop',
        url: '/shop',
        navItem: true,
        subItem: false
    },
    {
        title: 'About',
        url: '/about',
        navItem: true,
        subItem: false
    },
    {
        title: 'Contact',
        url: '/contact',
        navItem: true,
        subItem: false
    }
]

export default class Navigation {
    public static getPages () {
        return pages;
    }

    public static getAllNavItems () {
        return pages.filter(page => {
            return page.navItem === true
        })
    }

    public static getPrimaryNavItems () {
        return pages.filter(page => {
            return page.navItem === true && page.subItem === false
        });
    }
}
import * as React from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';

import DownArrowIcon from '../../components/icons/DownArrowIcon';

interface MenuOption {
    id: string;
    title: string;
}

interface Props {
    currentMenuId: MenuOption['id'];
    options: MenuOption[];
    selectMenu: (id: any) => void;
}

export default function MenuDropdown (props: Props) {
    const { currentMenuId, options, selectMenu } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuSelection = (id: string) => {
        selectMenu(id);
        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const optionsMap: { [id: string]: MenuOption } = {}

    options.map(option => {
        optionsMap[option.id] = option;
    })


    return (
        <Stack direction='row' spacing={4}>
            <Button
                id="basic-button"
                variant='outlined'
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color='secondary'
                endIcon={<DownArrowIcon color='secondary' size='small' />}
                sx={{ borderColor: 'highlight.main', width: '100%', justifyContent: 'space-between' }}>
                {optionsMap[currentMenuId].title}
            </Button>

            <MuiMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {
                    Object.values(options).map((option: MenuOption, i) => (
                        <MuiMenuItem key={i} onClick={() => handleMenuSelection(option.id)}>{option.title}</MuiMenuItem>
                    ))
                }
            </MuiMenu>
        </Stack>
    )

}
import Container from '../../components/containers/Container';

import Stack from '../../components/containers/Stack';
import GoatHeadIcon from '../../components/icons/GoatHeadIcon';
import AboveHeadLiftIcon from '../../components/icons/AboveHeadLiftIcon';
import ThreePeopleIcon from '../../components/icons/ThreePeopleIcon';
import Header2 from '../../components/text/Header2';
import Header3 from '../../components/text/Header3';
import Span from '../../components/text/Span';
import Paragraph from '../../components/text/Paragraph';
import Callout from '../../components/cards/Callout';
import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';

type WhoWhatWhyItem = {
    title: string;
    details: string;
    Icon: typeof GoatHeadIcon | typeof AboveHeadLiftIcon | typeof ThreePeopleIcon;
}

export default function WhoWhatWhy () {
    const isSmallScreen = useScreenSize('sm');

    const items: WhoWhatWhyItem[] = [
        {
            title: 'WHO WE ARE',
            details: 'A community-driven fitness facility that seeks to inspire and empower individuals of all ages and fitness levels to develop healthy habits and strong bodies.',
            Icon: GoatHeadIcon
        },
        {
            title: `WHAT WE'RE DOING`,
            details: `Encouraging movement to foster complete physical, mental, and social well-being. The Goat House will offer diverse classes, a state-of-the-art gym, personal coaching, existing programs for children and teens, and community events.`,
            Icon: AboveHeadLiftIcon
        },
        {
            title: `WHY WE'RE HERE`,
            details: `We are addressing a need in our community for a fitness facility that caters to individuals of all ages and fitness levels. Although we are surrounded by natural beauty, the lack of accessible fitness options is a real problem.`,
            Icon: ThreePeopleIcon
        }
    ]

    return (
        <Container styles={{ marginTop: theme.spacing(isSmallScreen ? 5 : 6), marginBottom: theme.spacing(isSmallScreen ? 5 : 6) }}>
            <Stack direction='column' spacing={isSmallScreen ? 3 : 4}>
                <Header2 fontWeight={700}>Building strength, confidence, and community.</Header2>

                <Callout>
                    <Header3><Span styles={{ verticalAlign: 'middle', fontWeight: 700, fontSize: '32px', fontFamily: 'Almonde' }}>The Goat House</Span> is now open in Granite County.</Header3>
                </Callout>

                <Stack direction={isSmallScreen ? 'column' : 'row'} justifyContent={isSmallScreen ? 'center' : 'space-around'} spacing={4}>
                    {
                        items.map(({ title, details, Icon }, i) => (
                            <Stack key={i} direction='column' alignItems='center' styles={{ width: isSmallScreen ? '100%' : '33.33%' }} spacing={4}>
                                <Icon />

                                <Header3 styles={{ marginTop: theme.spacing(5) }}>{title}</Header3>

                                <Paragraph textAlign='center'>{details}</Paragraph>
                            </Stack>
                        )) 
                    }
                </Stack>
            </Stack>
        </Container>
    )
}
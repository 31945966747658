import useMediaQuery from '@mui/material/useMediaQuery';

type BreakPointSizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type BreakpointsMap = {
    [BreapointSizes: string]: number;
}

const breakpoints: BreakpointsMap = {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1100,
    xl: 1300
}

const unit = 'px';

export function mediaQueryMaxWidth (size: BreakPointSizes) {
    return `@media (max-width: ${breakpoints[size]}${unit})`;
}

export function mediaQueryMinWidth (size: BreakPointSizes) {
    return `@media (min-width: ${breakpoints[size]}${unit})`;
}

export function useScreenSize (size: BreakPointSizes) {
    const isMatch = useMediaQuery(mediaQueryMaxWidth(size));

    return isMatch;
}

export default {
    values: {
        xs: breakpoints.xs,
        sm: breakpoints.sm,
        md: breakpoints.md,
        lg: breakpoints.lg,
        xl: breakpoints.xl
    }
}
import { ReactComponent as LighteningSvg } from './svgs/lightning.svg';

type LightningIconProps = {
    width: number;
    height: number;
}


export default function LighteningIcon (props: LightningIconProps) {
    const { width, height } = props;

    return (
        <LighteningSvg width={width} height={height} />
    )
} 
import TextBase, { TextProps } from './TextBase';

type SpanProps = TextProps & {
}

export default function Span (props: SpanProps) {
    return (
        <TextBase {...props} variant='span'>
            {props.children}
        </TextBase>
    )
}
import styled from 'styled-components';

import Stack from '../../components/containers/Stack';
import Header3 from '../../components/text/Header3';
import Paragraph from '../../components/text/Paragraph';
import { useScreenSize } from '../../theme/breakpoints';

type FounderCardProps = {
    name: string;
    description: string;
    photoUrl: string;
}

export default function FounderCard (props: FounderCardProps) {
    const { name, description, photoUrl } = props;
    const isSmallScreen = useScreenSize('sm');

    return (
        <Stack direction={isSmallScreen ? 'column' : 'row'} styles={{ margin: '25px 0' }} alignItems='center'>
            <Stack direction='column' alignItems={isSmallScreen ? 'center' : 'flex-start'}>
                <Header3 color='secondary.main' fontWeight={700} styles={{ padding: '10px 0' }}>{name}</Header3>

                <Image src={photoUrl} />
            </Stack>

            <Paragraph color='secondary.main' styles={{ margin: isSmallScreen ? '25px 0 0 0' : '0 0 0 100px' }}>{description}</Paragraph>
        </Stack>
    )
}

const Image = styled.img`
    width: 200px;
    height: 200px;
`
import { ReactComponent as DumbellSvg } from './svgs/dumbell.svg';

type DumbellIconProps = {
    width: number;
    height: number;
}


export default function DumbellIcon (props: DumbellIconProps) {
    const { width, height } = props;

    return (
        <DumbellSvg width={width} height={height} />
    )
} 
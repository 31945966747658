import HeroSection from '../../sections/hero/HeroSection';
import { useScreenSize } from '../../theme/breakpoints';

export default function Scholarships () {
    const isMediumScreen = useScreenSize('md')

    return (
        <HeroSection 
            height='75vh'
            cardWidth='40vw'
            heroImagePath='camryn_skiing.jpg'
            buttonHref='https://forms.gle/ZHzrD4DcuZv9aX5bA' 
            titleText='Scholarships Open' 
            descriptionText='See a membership program that catches your eye?' 
            buttonText='Apply Now'
            backgroundPosition={isMediumScreen ? '15%' : ''}
        />
    )
}